import Image from "next/image";
import React from "react";
import footerstyle from "./Footer.module.css"
import Logo from "../../assets/images/header/SIA Logo.png";
import parliament from "../../assets/images/footer/sia-ias-parliament.png";
import tnpsc from "../../assets/images/footer/shankar-ias-tnpsc.webp";
import banking from "../../assets/images/footer/shankar-ias-banking.webp";
import googleplay from "../../assets/images/footer/google.png";
import apple from "../../assets/images/footer/apple.png";
import fb from "../../assets/images/footer/fb.svg";
import tw from "../../assets/images/footer/tw.svg";
import insta from "../../assets/images/footer/insta.svg";
import yt from "../../assets/images/footer/yt.svg";
import mail from "../../assets/images/footer/mail.svg";
import call from "../../assets/images/footer/call.svg";
import Link from "next/link";

const Footer = () => {
  return (
    <>
      <div className="container pt-md-5 pt-4">
        {/* <!-- Large Devices --> */}
        <div className="container px-0 d-none d-md-block d-sm-none">
          <div className="row pt-3">

              <div className={`${footerstyle.footerimg1}`}>

                <div className={`${footerstyle.footerimg2}`}>
                <Link href="/" rel="noreferrer">
                  <Image
                    src={Logo}
                    alt="shankar ias logo"
                    title="Shankar IAS Academy"
                    className="img-fluid"
                  />
                  </Link>
                </div>
                <div className={`${footerstyle.footerimg2}`}>
                  <a href="https://www.iasparliament.com/"  target="_blank" rel="noreferrer">
                    <Image
                      src={parliament}
                      alt="ias parliment logo"
                      title="IAS Parliment"
                      className="img-fluid"
                    />
                  </a>
                </div>
                <div className={`${footerstyle.footerimg2}`}>
                  <a href="https://www.tnpscthervupettagam.com/" target="_blank" rel="noreferrer" >
                    <Image
                      src={tnpsc}
                      alt="tnpsc thervupettagam logo"
                      title="TNPSC Thervupettagam"
                      className="img-fluid"
                    />
                  </a>
                </div>
                <div className={`${footerstyle.footerimg2}`}>
                  <a href="https://www.shankarbankingacademy.com/" target="_blank" rel="noreferrer" >
                    <Image
                      src={banking}
                      alt="shankar banking academy logo"
                      title="Shankar Banking Academy"
                      className="img-fluid"
                    />
                  </a>
                </div>

              </div>

          </div>
        </div>
        {/* 
    <!-- Small Devices --> */}
        <div className="container d-block d-md-none d-sm-block">
          <div className="row text-center">
            <div className="col-12 pt-4">
            <Link href="/" rel="noreferrer">
              <Image
                src={Logo}
                alt="shankar ias logo"
                title="Shankar IAS Academy"
                className="img-fluid"
              />
            </Link>
            </div>
            <div className="col-12 pt-4">
              <a href="https://www.iasparliament.com/" target="_blank" rel="noreferrer" >
                <Image
                  src={parliament}
                  alt="ias parliment logo"
                  title="IAS Parliment"
                  className="img-fluid"
                />
              </a>
            </div>
          </div>
          <div className="row text-center">
            <div className="col-12 pt-4">
              <a href="https://www.tnpscthervupettagam.com/" target="_blank" rel="noreferrer">
                <Image
                  src={tnpsc}
                  alt="tnpsc thervupettagam logo"
                  title="TNPSC Thervupettagam"
                  className="img-fluid"
                />
              </a>
            </div>
            <div className="col-12 pt-4">
              <a href="https://www.shankarbankingacademy.com/" target="_blank" rel="noreferrer">
                <Image
                  src={banking}
                  alt="shankar banking academy logo"
                  title="Shankar Banking Academy"
                  className="img-fluid"
                />
              </a>
            </div>
          </div>
        </div>

        <hr className="hr text-center"/>

        <div className="container pt-2 px-0">
          <div className="row">
            <div className="col-md-3">
              <p className={`${footerstyle.footerheading} mb-md-2 mb-1`}>General</p>
              <div className="row">
                <div className="col-md-4 col-6">
                  <ul className="list-unstyled mb-0"> 
                    <li>
                      <Link href="/">
                        <span  className={`${footerstyle.listcontent}`}>
                          Home
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link href="/about-us">
                        <span  className={`${footerstyle.listcontent}`}>
                          About
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link href="/contact-us">
                        <span  className={`${footerstyle.listcontent}`}>
                          Contact
                        </span>
                      </Link>
                    </li>
                    <li>
                    <Link href="/careers">
                      <span className={`${footerstyle.listcontent}`}>
                        Careers
                      </span>
                    </Link>
                    </li>
                    <li>
                      <a href="https://www.shankariasacademy.com/blog/" target="_blank" rel="noreferrer" className={`${footerstyle.listcontent}`}>
                        Blog
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col">
                  <ul className="list-unstyled">
                    <li>
                      <a href="https://www.shankariasacademy.com/postalmaterial/" rel="noreferrer" className={`${footerstyle.listcontent}`}>
                      Study Materials
                      </a>
                    </li>
                    <li>
                      <Link href="/our-achievers" className={`${footerstyle.listcontent}`}>
                        Achievers
                      </Link>
                    </li>
                    <li>
                      <a
                        href="https://admissions.shankariasacademy.com/online/" target="_blank" rel="noreferrer"
                        
                        className={`${footerstyle.listcontent}`}
                      >
                        Login
                      </a>
                    </li>
                    <li>
                      <Link href="/admission-consultation">
                        <span  className={`${footerstyle.listcontent}`} style={{whiteSpace:"pre"}}>
                          Admission Consultation
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-3">
              <p className={`${footerstyle.footerheading} mb-md-2 mb-1`}>UPSC Courses</p>
              <ul className="list-unstyled">
                <li>
                  <Link href="/about-upsc">
                    <span  className={`${footerstyle.listcontent}`}>
                      About Civil Service
                    </span>
                  </Link>
                </li>
                <li>
                  <Link href="/upsc-admission-anna-nagar">
                    <span  className={`${footerstyle.listcontent}`}>
                    UPSC - Prelims cum Mains (Foundation Course)
                    </span>
                  </Link>
                </li>
                <li>
                  <Link href="/two-year-upsc-program">
                    <span  className={`${footerstyle.listcontent}`}>
                    Sadhana (II Year UPSC Foundation Course)
                    </span>
                  </Link>
                </li>

                <li>
                  <Link href="/upsc-interview">
                    <span  className={`${footerstyle.listcontent}`}>
                    UPSC Civilisation interview 2024
                    </span>
                  </Link>
                </li>

                <li>
                  <Link href="/current-affairs-upsc">
                    <span  className={`${footerstyle.listcontent}`}>
                    Chakra Current Affairs Program 2024
                    </span>
                  </Link>
                </li>

                <li>
                <li>
                  <Link href="/upsc-mains-test-series">
                  <span
                    className={`${footerstyle.listcontent}`}
                  >
                   UPSC GS-Mains Test Series 2024 (Moksha)
                  </span>
                  </Link>
                </li>
                  <Link href="/about-forestry">
                    <span  className={`${footerstyle.listcontent}`}>
                      About Forest Service
                    </span>
                  </Link>
                </li>
                

                <li>
                  <Link href="/upsc-optional-test-series">
                  <span className={`${footerstyle.listcontent}`}>
                  UPSC Optional Test Series 2024
                  </span>
                  </Link>
                </li>

                <li>
                  <Link href="/upsc-all-india-mains-mock-test">
                  <span
                    className={`${footerstyle.listcontent}`}
                  >
                   UPSC All India Mains Mock Test 2024
                  </span>
                  </Link>
                </li>

                <li>
                  <Link href="/upsc-prestorming">
                  <span className={`${footerstyle.listcontent}`}>
                  UPSC Prelims Test Series 2025
                  </span>
                  </Link>
                </li>

                <li>
                  <Link href="/upsc-ifos-optional-test-series">
                  <span className={`${footerstyle.listcontent}`}>
                  UPSC Forestry Optional Test Series 2024
                  </span>
                  </Link>
                </li>


                <li>
                  <Link href="/forestry-admission">
                  <span className={`${footerstyle.listcontent}`}>
                  UPSC Forestry Optional
                  </span>
                  </Link>
                </li>

                <li>
                  <Link href="/upsc-environment-book">
                  <span className={`${footerstyle.listcontent}`}>
                  UPSC Environment Book Buy Now
                  </span>
                  </Link>
                </li>
                
              </ul>
            </div>

            <div className="col-md-3 pe-0">
              <p className={`${footerstyle.footerheading} mb-md-2 mb-1`}>TNPSC Courses</p>
              <ul className="list-unstyled">
               
                <li>
                <Link href="/tnpsc-admissions">
                  <span className={`${footerstyle.listcontent}`}>
                  Gr I, II & IIA Prelims Coaching
                  </span>
                  </Link>
                </li>
               

                <li>
                  <Link href="/tnpsc-group-iv-test-batch">
                    <span className={`${footerstyle.listcontent}`}>
                       TNPSC Group IV Prelims Test Series 2024
                    </span>
                  </Link>
                </li>

                <li>
                  <Link href="/tnpsc-group-i-prelims-test-series">
                    <span className={`${footerstyle.listcontent}`}>
                       TNPSC Group I Prelims Test Series 2024 
                    </span>
                  </Link>
                </li>


                <li>
                  <Link href="/tnpsc-group-II-IIA-mains-test-series">
                    <span className={`${footerstyle.listcontent}`}>
                        TNPSC Group II/IIA Mains Test Series 2024
                    </span>
                  </Link>
                </li>

                    <li>
                        <Link href="/tnpsc-group-II-mains-test-series" >
                        <span className={`${footerstyle.listcontent}`}>
                         TNPSC Group II Mains Test Series 2024
                        </span>
                        </Link>
                    </li>
                    <li>
                    <Link href="/tnpsc-group-IIA-mains-test-series" >
                    <span className={`${footerstyle.listcontent}`}>
                    TNPSC Group IIA Mains Test Series 2024
                    </span>
                    </Link>
                    </li>
                <li>
                  <Link href="/tnpsc-group-i-mains-test-series">
                  <span className={`${footerstyle.listcontent}`}>
                  TNPSC Group I Mains Test Series 2024
                  </span>
                  </Link>
                </li>
                <li>
                  <Link href="/tnpsc-group-i-mains-crash-course">
                  <span className={`${footerstyle.listcontent}`}>
                  TNPSC Group I Mains Crash Course 2024
                  </span>
                  </Link>
                </li>
                <li>
                  <Link href="/si-test-series">
                  <span className={`${footerstyle.listcontent}`}>
                  TNUSRB-SI Test Series 2024
                  </span>
                  </Link>
                </li>
                
              </ul>
            </div>

            <div className="col-md-3">
              <p className={`${footerstyle.footerheading} mb-md-2 mb-1`}>Banking Courses</p>
              <ul className="list-unstyled">

                
                <li>
                    <a  href="https://www.shankarbankingacademy.com/ssc-banking-admission/"  target="_blank" rel="noreferrer"className={`${footerstyle.listcontent}`}>
                    BANKING / SSC Admission</a>
                </li>

                <li>
                  <Link href="https://www.shankarbankingacademy.com/ssc-banking-admission/"className={`${footerstyle.listcontent}`}target="_blank" rel="noreferrer">
                  SSC CGL CRASH COURSE 2024</Link>
                </li>

              </ul>

              <div className="pt-md-5">
                <a href="https://play.google.com/store/apps/details?id=com.vinbytes.shankar_ias" target="_blank" rel="noreferrer">
                  <Image
                    src={googleplay}
                    alt="googleplay icon"
                    title="Googleplay"
                   className={`${footerstyle.img} img-fluid`}
                    id={footerstyle.googleplay}
                  />
                </a>
                <br />
                <a href="https://apps.apple.com/in/app/shankar-ias/id1611747971" target="_blank" rel="noreferrer">
                  <Image
                    src={apple}
                    alt="appstore icon"
                    title="Appstore"
                   className={`${footerstyle.img} img-fluid`}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>

        <hr className="hr text-center d-sm-none d-md-block" />

        <div className="container">
          <div className="row text-center">
            <div className="col">
              <span className="pe-3">
                <a
                  href="https://www.facebook.com/shankariasacademy" target="_blank" rel="noreferrer"           
                >
                  <Image
                    src={fb}
                    alt="facebook icon"
                    title="Facebook"
                   className={`${footerstyle.img} img-fluid`}
                  />
                </a>
              </span>
              <span className="pe-3">
                <a href="https://twitter.com/shankarias" target="_blank" rel="noreferrer" >
                  <Image
                    src={tw}
                    alt="twitter icon"
                    title="Twitter"
                   className={`${footerstyle.img} img-fluid`}
                  />
                </a>
              </span>
              <span className="pe-3">
                <a
                  href="https://www.instagram.com/shankariasacademy/" target="_blank" rel="noreferrer"                
                >
                  <Image
                    src={insta}
                    alt="instagram icon"
                    title="Instagram"
                   className={`${footerstyle.img} img-fluid`}
                  />
                </a>
              </span>
              <span className="pe-3">
                <a
                  href="https://www.youtube.com/c/ShankarIASAcademyVideos" target="_blank" rel="noreferrer"                 
                >
                  <Image
                    src={yt}
                    alt="youtube icon"
                    title="Youtube"
                   className={`${footerstyle.img} img-fluid`}
                  />
                </a>
              </span>
            </div>
          </div>

          <div className="row pt-3">
            <div className="col">
              {/* <!-- Largedevice --> */}
              <div className={`${footerstyle.footer} text-center mb-0 d-none d-md-block`}>
                <Image src={mail} alt="mail icon" title="Mail Link"/>{" "}
                <a href="mailto:admissions@shankarias.in" className={`${footerstyle.footer}`}>
                  {" "} 
                 admissions@shankarias.in 
                </a>{" "} &nbsp; 
                <Image src={call} alt="mobile icon" title="Mobile" /> UPSC:{" "}
                <a href="tel: 044-66024500" className={`${footerstyle.footer}`}>
                7667766266 &nbsp;|&nbsp; 044-66024500
                </a>{" "}
                <span className={`${footerstyle.dotlarge}`}><b>.</b></span> TNPSC:{" "}
                <a href="tel:044-43533445" className={`${footerstyle.footer}`}>
                 044-43533445 &nbsp;|&nbsp; 044-45543082
                </a>
              </div>

              {/* <!-- Smalldevice --> */}
              <div className={`${footerstyle.footer} text-center mb-0 d-block d-md-none`}>
                <div>
                  <Image src={mail} alt="mail icon" title="Mail Link" />{" "}
                  <a href="mailto:admissions@shankarias.in" className={`${footerstyle.footer}`}>
                    {" "}
                   admissions@shankarias.in 
                  </a>
                </div>
                <div className="pt-2">
                  <Image src={call} alt="mobile icon" title="Mobile" /> UPSC:{" "}
                  <a href="tel: 044-66024500" className={`${footerstyle.footer}`}>
                  7667766266 &nbsp;|&nbsp; 044-66024500
                  </a>{" "}
                  <br className={`${footerstyle.mobilenum}`} /><span className={`${footerstyle.dotlarge}`}><b>.</b></span> TNPSC:{" "}
                  <a href="tel:044-43533445" className={`${footerstyle.footer}`}>
                   044-43533445 &nbsp;|&nbsp; 044-45543082
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="row text-center">
            <div className="col">
              <span>
                <a href="/privacy-policy" className={`${footerstyle.footercontent}`}>
                  Privacy Policy |
                </a>{" "}
              </span>
              <a href="/terms-and-conditions" className={`${footerstyle.footercontent}`}>
              Terms and Conditions
              </a>
            </div>
          </div>

          <div className="row text-center">
            <div className="col pt-1">
              <p className={`${footerstyle.footercontent}`}>Shankar IAS Academy © 2024.</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
